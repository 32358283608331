import consumer from "./consumer"

consumer.subscriptions.create({
  channel: "AdminNotificationChannel",
}, {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected to the room!")
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    var notification = null;

    function ShowNotification(title, body) {
      notification = new Notification(
        title, {
          icon: '',
          body: body,
          onclick: function () {
            parent.focus();
            window.focus();
            this.close();
          }
        })
    }

    if (data.obj.noty){

      new Noty({
        timeout: false,
        closeWith: ['click'],
        type: "warning",
        text: data.obj.noty_message,
      }).show();

      ShowNotification(data.obj.web_notification_title, data.obj.web_notification_body);

      if($("#alert-ring").length > 0){
        $("#alert-ring")[0].pause()
        $("#alert-ring")[0].currentTime = 0
        $("#alert-ring")[0].play()
      }

    }

  }
});

// let submit_messages;

// $(document).on('turbolinks:load', function () {
//   submit_messages()
// })

// submit_messages = function () {
//   $('#message_content').on('keydown', function (event) {
//     if (event.keyCode == 13) {
//       $('input').click()
//       event.target.value = ''
//       event.preventDefault()
//     }
//   })
// }